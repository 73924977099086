<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('promotion.head') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('promotion.head_list') }} </v-card-title>
        <v-card-actions class="pb-0 d-flex align-end">
          <v-spacer />
          <v-btn color="primary" class="px-5" @click="$router.push('/promotion/promotion_form_create')">
            <v-icon class="px-3">mdi-plus</v-icon>
            <span class="pr-3">{{ $t('promotion.create') }}</span>
          </v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-spacer />
          <span class="px-3 mt-5">{{ $t('search') }}:</span>
          <div style="width: 150px" class="mt-5">
            <v-text-field v-debounce:500ms="searchKeyword" outlined dense hide-details />
          </div>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :items="itemsData"
            :no-data-text="$t('not_found')" :no-results-text="$t('not_found')" :options.sync="pagination"
            :server-items-length="pagination.totalItems" :items-per-page="pagination.rowsPerPage"
            :page.sync="pagination.page" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :loading="loading" :search="search">
            <template v-slot:[`item.enable`]="{ item }">
              <span v-if="item.enable" class="success--text">{{ $t('open') }}</span>
              <span v-if="!item.enable" class="error--text">{{ $t('close') }}</span>
            </template>

            <template v-slot:[`item.format`]="{ item }">
              {{ formatText(item.format) }}
            </template>

            <template v-slot:[`item.type`]="{ item }">
              {{ typeText(item.type) }}
            </template>

            <template v-slot:[`item.min_deposit`]="{ item }">
              <span>{{ item.min_deposit || "-" }}</span>
            </template>

            <template v-slot:[`item.format_fix_deposit`]="{ item }">
              <span>{{ item.format === 'fix_deposit_bonus' ? item.fix_deposit_bonus : "-" }}</span>
            </template>

            <template v-slot:[`item.format_bonus_percent`]="{ item }">
              <span>{{ item.format === 'normal' || item.format === 'normal_min_deposit' ? item.percent : "-" }}</span>
            </template>

            <template v-slot:[`item.format_fix_bonus_percent`]="{ item }">
              <span>{{ (item.format === 'normal_min_deposit_fix_bonus' && item.percent > 0 && item.fix_deposit_bonus ===
                0) ? item.percent : "-" }}</span>
            </template>

            <template v-slot:[`item.format_fix_bonus_amount`]="{ item }">
              <span>{{ (item.format === 'normal_min_deposit_fix_bonus' && item.percent === 0 && item.fix_deposit_bonus >
                0) ? item.fix_deposit_bonus : "-" }}</span>
            </template>

            <template v-slot:[`item.max_amount`]="{ item }">
              <span v-if="item.format !== 'fix_deposit_bonus'">{{ item.max_amount || "-" }}</span>
              <span v-else>{{ "-" }}</span>
            </template>

            <template v-slot:[`item.fix_deposit_bonus`]="{ item }">
              <span v-if="item.format === 'fix_deposit_bonus'">{{ item.max_amount }}</span>
              <span v-else-if="item.format === 'normal' || item.format === 'normal_min_deposit'">{{ item.fix_deposit_bonus
                || "-" }}</span>
              <span v-else>{{ "-" }}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-menu transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="success" dark v-bind="attrs" v-on="on">
                    <v-icon small color="white">mdi-square-edit-outline</v-icon>
                    <span class="px-3 white--text">{{ $t('manage') }}</span>
                    <v-icon small color="white">mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="$router.push(`/promotion/promotion_form_update/${item.id}`)">
                    <v-list-item-title>
                      <v-icon small color="warning">mdi-square-edit-outline</v-icon>
                      <span class="px-4">{{ $t('edit') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider />
                  <v-list-item @click="deletePromotionData(item)">
                    <v-list-item-title>
                      <v-icon small color="error">mdi-delete</v-icon>
                      <span class="px-4">{{ $t('delete') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import moment from 'moment'
import store from '@/store'
import { getCurrency, getCurrencyI18n } from '@/utils/currencyUtils'


export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      menu: false,
      showDetail: false,
      toggle_exclusive: 0,
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      date: null,
      currentMemberDetail: {},
      headers: [
        {
          text: this.$t('promotion.header1'),
          value: 'format',
          width: '150px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('promotion.header2'),
          value: 'type',
          width: '50px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('promotion.header3'),
          value: 'name',
          width: '200px',
          align: 'center',
          sortable: false,
        },

        {
          text: this.getCurrencyI18n(this.$t('promotion.header5')),
          value: 'min_deposit',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.getCurrencyI18n(this.$t('promotion.header7_3')),
          value: 'format_fix_deposit',
          width: '100px',
          align: 'center',
          sortable: false,
        },

        {
          text: this.$t('promotion.header6_2'),
          value: 'format_bonus_percent',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.getCurrencyI18n(this.$t('promotion.header6_3')),

          value: 'format_fix_bonus_amount',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('promotion.header6_4'),
          value: 'format_fix_bonus_percent',
          width: '100px',
          align: 'center',
          sortable: false,
        },

        {
          text: this.getCurrencyI18n(this.$t('promotion.header7_2')),
          value: 'max_amount',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.getCurrencyI18n(this.$t('promotion.header11')),
          value: 'fix_deposit_bonus',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('promotion.header8'),
          value: 'multiply_turnover',
          width: '100px',
          align: 'center',
          sortable: false,
        },

        {
          text: this.$t('promotion.header4'),
          value: 'max_per_user',
          width: '100px',
          align: 'center',
          sortable: false,
        },

        {
          text: this.$t('promotion.header9'),
          value: 'enable',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('promotion.header10'),
          value: 'actions',
          width: '95px',
          align: 'center',
          sortable: false,
        },
      ],
      itemsData: [],
      search: '',
      loading: false,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
      formItems: [
        {
          text: this.$t('promotion.form_item1'),
          value: 'normal',
        },
        {
          text: this.$t('promotion.form_item2'),
          value: 'normal_min_deposit',
        },
        {
          text: this.$t('promotion.form_item3'),
          value: 'normal_min_deposit_fix_bonus',
        },
        {
          text: this.$t('promotion.form_item4'),
          value: 'fix_deposit_bonus',
        },
      ],
      typeItems: [
        {
          text: this.$t('promotion.type_item1'),
          value: 'forever',
        },
        {
          text: this.$t('promotion.type_item2'),
          value: 'daily',
        },
        {
          text: this.$t('promotion.type_item3'),
          value: 'weekly',
        },
        {
          text: this.$t('promotion.type_item4'),
          value: 'monthly',
        },
      ],
      bankSystem: '',
    }
  },
  async created() {
    this.addLogPage()
    this.getWebSettingInfo()
    // const result = await this.getPromotionData(1, this.pagination.itemsData)
    // this.pagination.totalItems = result.count
    // this.itemsData = result.data
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if (cont) {
          this.loading = true
          const result = await this.getPromotionData(newValue.page, newValue.itemsPerPage)
          this.pagination.totalItems = result.count
          this.itemsData = result.data
          // this.$store.dispatch('queryItems')
          // .then(result => {
          this.loading = false
        }

        // })
      },
      deep: true,
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          data = {
            ip: res.data.ip,
            name: 'โปรโมชั่น',
            url: window.location.href,
            detail: 'ระบบจัดการโปรโมชั่น',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getPromotionData(page, row) {
      try {
        return await this.$store.dispatch(
          'getPromotion',
          `page=${page}&rows=${row}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}`
        )
      } catch (e) { }
    },
    async deletePromotionData(item) {
      this.$swal
        .fire({
          icon: 'warning',
          title: this.$t('alert'),
          text: `${this.$t('alert_list.list11')} ${item.name}`,
          showCancelButton: true,
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
        })
        .then(async result => {
          if (result.isConfirmed) {
            await this.$store.dispatch('deletePromotion', item.id)
            this.$swal.fire(this.$t('alert_list.list12'), '', 'success')
            const result = await this.getPromotionData(1, 25)
            this.pagination.totalItems = result.count
            this.itemsData = result.data
            this.loading = false
          }
        })
    },
    async searchKeyword(value) {
      this.loading = true
      try {
        let result = {}
        if (value) {
          result = await this.$store.dispatch(
            'getPromotion',
            `page=1&rows=${this.pagination.rowsPerPage}&search=${value}`,
          )
        } else {
          result = await this.$store.dispatch('getPromotion', `page=1&rows=${this.pagination.rowsPerPage}`)
        }
        this.pagination.totalItems = result.count
        this.itemsData = result.data
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
      this.pagination.page = 1
      this.loading = false
    },
    formatText(format) {
      const rs = this.formItems.find(x => x.value === format)

      return rs.text
    },
    typeText(type) {
      const rs = this.typeItems.find(x => x.value === type)

      return rs.text
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
    async getWebSettingInfo() {
      try {
        let settingWebInfo = await this.$store.dispatch('getSettingWebInfo')
        this.bankSystem = settingWebInfo?.bank_system[0] || 'TH';
      } catch (e) { }
    },
    getCurrency() {
      return getCurrency(this.bankSystem);
    },
    getCurrencyI18n(message) {
      console.log(message + "<<<<<");
      return getCurrencyI18n(this.bankSystem, message);
    },
  },
}
</script>
